@font-face {
    font-family: 'NanumSquare';
    src: local("NanumSquareR"), url("./resources/fonts/NanumSquareR.otf") format('otf');
    font-weight: normal;
    font-style: normal;
}
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);


:root {
    --global-width: 80vw;
    --chip-main: #6D8018;

    --primary-font-color: #262626;
    --primary-border-color: #ddcec1;

    --notice-background-color: #F9f5f1;

    --booking-background-color: #f5f0ea;
    --booking-font-color: #767171;

    --banner-background-color: #cfb9a6;
    --banner-font-color: #ffffff;

    --contents-background-color: #fdfcf9;

    --font_spoqa: "Spoqa Han Sans Neo", "sans-serif";
    --font-nanum-square: "NanumSquareR";
}

a {
    color: var(--primary-font-color);
    text-decoration: transparent;
    width: inherit;
    height: inherit;
}

img {
    width: inherit;
    height: inherit;
}

p {
    width: inherit;
    height: inherit;
    margin: inherit;
}

.App {
    text-align: center;
    --sj-bg-color: #ffffff;
}

.App-logo {
    height: 20vh;
    align-content: center;
    pointer-events: none;
}

.App-header {
    background-color: var(--sj-bg-color);
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-header img {

}

.App-navigation {
    background-color: #ffffff;
}

.App-link {
    color: #61dafb;
}

.navigation-section {
    position: relative;
    height: calc(2vh + 1rem);
    width: var(--global-width);
    margin-top: 0.5vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-bottom: var(--primary-border-color) solid 1px;
    font-family: var(--font_spoqa);
}

.nav-item {
    position: relative;
    box-sizing: content-box;
    width: 10ch;
    height: fit-content;
    float: left;
    display: block;
    font-size: 1rem;
    color: var(--primary-font-color);
}

.nav-link {
    position: relative;
    box-sizing: content-box;
    margin-right: 0.5vw;
    width: 2.5vh;
    height: 2.5vh;
    float: right;
}

.banner {
    --banner-height: calc(var(--global-width) / 16 / 3 * 9);

    width: calc(var(--global-width) + 20px);
    height: var(--banner-height);
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: var(--font_spoqa);
}

.banner-item {
    width: calc(var(--global-width) / 3 - 2px - 20px);
    height: inherit;
    border: #dfdfdf solid 1px;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.banner-item img {
    border-radius: 5px;
}

.banner-item p {
    --item-font-size: calc(var(--banner-height) / 4);

    width: fit-content;
    height: fit-content;
    line-height: var(--banner-height);
    border-radius: 5px;
    text-align: center;
    vertical-align: center;
    /*margin-top: calc((var(--banner-height) ) / 2 - var(--item-font-size));*/
    margin-left: auto;
    margin-right: auto;
    font-size: var(--item-font-size);
}

.banner-invisible {
    width: calc(var(--global-width) / 3);
    height: inherit;
    float: left;
    display: block;
}

.banner-item-primary {
    color: var(--booking-font-color);
    background-color: var(--booking-background-color);
}

.banner-item-secondary {
    color: var(--banner-font-color);
    background-color: var(--banner-background-color);
}

.notice {
    width: var(--global-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    text-align: start;
    display: block;
    background: var(--notice-background-color);
    border: var(--primary-border-color) solid 1px;
    font-family: var(--font-nanum-square);
}

.notice-item {
    --item-height: 3vh;
    --item-font-size: 1rem;

    height: var(--item-height);
    font-size: var(--item-font-size);
    text-align: left;
    color: var(--primary-font-color);
    vertical-align: top;
    margin-left: 1vh;
    display: block;
    padding-top: calc((var(--item-height) - var(--item-font-size)) / 2);
}

.notice-item p {
    height: fit-content;
}

.notice-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    content: url("./resources/outline_campaign_black_18dp.png");
    /*background: linear-gradient(rgba(155, 128, 24, 25), rgba(155, 128, 24, 25)), url("./resources/outline_campaign_black_18dp.png");*/
    filter: ;
    float: left;
    margin-right: 1vw;
    margin-top: calc(1rem / 5);
    vertical-align: -50%;
}

/*.notice-item:before {*/
/*    display: inline-block;*/
/*    width: 1rem;*/
/*    height: 1rem;*/
/*    content: url("./resources/outline_campaign_black_18dp.png");*/
/*    float: left;*/
/*    margin-right: 1vw;*/
/*    vertical-align: -50%;*/
/*}*/

.booking {
    --booking-height: 8vh;
    --booking-font-size: 2.4rem;

    font-size: var(--booking-font-size);
    width: var(--global-width);
    height: var(--booking-height);
    text-align: center;
    border: beige solid 2px;
    border-radius: 5px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    vertical-align: baseline;
    padding-top: calc((var(--booking-height) - var(--booking-font-size)) / 2);
}

.contents-section {
    --padding-size: 1.5vmin;

    width: calc(var(--global-width) - var(--padding-size) * 2);
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    font-size: 1.2rem;
    text-align: start;
    color: var(--primary-font-color);
    border: var(--primary-border-color) solid 2px;
    border-radius: 5px;
    padding: var(--padding-size);
    background-color: var(--contents-background-color);
    font-family: var(--font-nanum-square);
}

.footer {
    width: var(--global-width);
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh;
    border-top: #dfdfdf solid 1px;
    font-size: 0.9rem;
    color: #999999;
    text-align: start;
    font-family: var(--font-nanum-square);
}

.footer-span {
    margin-top: 2vh;
    width: content-box;
    height: auto;
}
